export interface DxpDocument {
  id?: string;
  farm_id?: string;
  process_type?: ProcessType;
  url?: string;
  documentable_type?: string;
  documentable_id?: string;
  document_key?: string;
  email?: string;
  variables?: Variables | Variables[] | any;
  created_at?: string;
  updated_at?: string;
  document_type?: string;
  name?: string;
  rules?: Rules;
  type?: Rules;
  batch_id?: string;
  document?: string;
  message?: string;
  warnings?: string;
  temporary_url?: string;
}

export interface DocumentGenerate {
  documentable_id: string;
  documentable_type: string;
  document_key: string;
  email: string;
  variables: any;
}

export interface DocumentBatch {
  batch_id: string;
  document: string;
  message: string;
}

export enum ProcessType {
  job = 'job',
}

export enum FieldType {
  comments = 'comments',
  WysiwygHtmlRule = 'WysiwygHtmlRule',
}

export interface Variables {
  field?: string;
  value?: string;
  weight?: number;
  comments?: string;
  filename?: string;
  fieldType?: FieldType;
}

interface Rules {
  [key: string]: string;
}

export enum DownloadActions {
  download = 'download',
  regenerate = 'regenerate',
}
