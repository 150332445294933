import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DocumentBatch, DocumentGenerate, DxpDocument, ProcessType, Variables } from '@core/models/document.model';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult, ListOptions } from '@capturum/api';
import { Observable, of } from 'rxjs';
import { BatchStatusService } from '@core/api/batch-status.service';
import { responseData } from '@capturum/builders/core';
import { EntityAction } from '@core/enums/entity-action.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends ApiService<DxpDocument> {
  protected endpoint = 'document';

  constructor(
    public apiHttpService: ApiHttpService,
    public http: HttpClient,
    public batchStatusService: BatchStatusService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    super(apiHttpService);
  }

  public create<T = DxpDocument>(data: any, options?: ListOptions): Observable<T> {
    return super.create(data, options).pipe(
      switchMap((document) => {
        return this.checkFileGenerateByJob(document) as Observable<T>;
      }),
      tap((document: any) => {
        if (document?.url) {
          this.downloadDocument(document);
        } else {
          return of(null);
        }
      }),
    );
  }

  public regenerate(id: string, email?: string, options?: ListOptions): Observable<DxpDocument> {
    let url = `/${this.endpoint}/${id}/regenerate`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    if (email) {
      url += `/${email}`;
    }

    return this.apiHttp.get<ApiSingleResult<DxpDocument>>(url).pipe(
      map((response) => {
        return response?.data;
      }),
      switchMap((document) => {
        return this.checkFileGenerateByJob(document);
      }),
    );
  }

  public checkFileGenerateByJob(document: DxpDocument, notify = true): Observable<DxpDocument> {
    if (document?.process_type === ProcessType.job) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      });

      return this.http.get(document.url, { headers, responseType: 'blob' }).pipe(
        catchError(() => {
          if (notify) {
            const title = this.translateService.instant('dxp.toast.info.title');
            const message = this.translateService.instant('dxp.toast.download.take_few_minutes');

            this.notificationService.info(title, message);
          }

          return of({ ...document, url: null }) as Observable<DxpDocument>;
        }),
        map((blob: Blob) => {
          return blob.size ? document : { ...document, url: null };
        }),
      );
    }

    return of(document);
  }

  public downloadDocument(document: DxpDocument, action?: string): void {
    this.apiHttpService
      .get(`/${this.endpoint}/${document?.id}${ApiService.generateQuery({ include: ['temporaryUrl'] })}`)
      .pipe(
        responseData,
        switchMap((updatedDocument: DxpDocument) => {
          if (updatedDocument.temporary_url) {
            return this.http.get(updatedDocument.temporary_url, { responseType: 'blob' });
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((response) => {
        if (response) {
          saveAs(response, this.getFileName(document));
          this.notify(document, action);
        }
      });
  }

  public regenerateDocument(document: DxpDocument): void {
    this.regenerate(document?.id)
      .pipe(
        switchMap((updatedResponse) => {
          return this.http.get(updatedResponse.url, { responseType: 'blob' });
        }),
      )
      .subscribe((file) => {
        saveAs(file, this.getFileName(document));
        this.notify(document);
      });
  }

  public documentGeneration(data: DocumentGenerate): Observable<DocumentBatch> {
    const url = `/${this.endpoint}`;

    return this.apiHttp.post<DocumentBatch>(url, data).pipe(
      tap((batchStatus) => {
        this.listenOnBatch(batchStatus?.batch_id, batchStatus?.document);
      }),
    );
  }

  public listenOnBatch(batchId: string, documentId: string): void {
    let fileName: string[];

    this.batchStatusService
      .getIsUpdatedBatch(batchId, false)
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => {
          if (documentId) {
            return this.apiHttpService.get(
              `/${this.endpoint}/${documentId}${ApiService.generateQuery({ include: ['temporaryUrl'] })}`,
            );
          }

          return of(null);
        }),
        responseData,
        switchMap((response: DxpDocument) => {
          fileName = response.url?.split('/').reverse();

          if (response?.warnings) {
            this.notificationService.warning(
              this.translateService.instant('dxp.toast.warning.title'),
              response.warnings,
            );
          }

          if (response?.temporary_url) {
            return this.http.get(response.temporary_url, { responseType: 'blob' });
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((updatedResult) => {
        if (updatedResult) {
          saveAs(updatedResult, fileName[0]);

          this.notificationService.entityActionSuccess('item', EntityAction.download, true);
        }
      });
  }

  private saveDocument(document: DxpDocument, fileName?: string): void {
    saveAs(document?.temporary_url ? document.temporary_url : document.url, fileName || this.getFileName(document));
  }

  private getFileName(document: DxpDocument): string {
    const variables = document?.variables as Variables;
    const splitUrl: string[] = document.url?.split('/');

    return variables?.filename || (splitUrl?.length ? splitUrl[splitUrl.length - 1] : null);
  }

  private notify(document: DxpDocument, action?: string): void {
    const notifyAction = action ? action : document?.email ? 'resent' : 'downloaded';

    const title = this.translateService.instant('toast.success.title');
    const message = this.translateService.instant(`dxp.packing-order.document-${notifyAction}`);

    this.notificationService.success(title, message);
  }
}
